<template>
	<span
		class="text-gray-700 rounded-full relative w-10 h-10 bg-neutral-50 hover:bg-gray-200 flex items-center justify-center"
	>
		<img
			v-if="$auth.getAvatar"
			class="rounded-full bg-gray-500 object-cover w-full h-full"
			:src="$auth.getAvatar"
			alt="profile photo"
		/>

		<UIcon
			v-else
			name="heroicons:building-office"
			size="22"
			class=""
		/>
	</span>
</template>
